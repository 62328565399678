export enum EErrorTypes {
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  REST_ERROR = 'REST_ERROR',
  REST_NOT_FOUND_ERROR = 'REST_NOT_FOUND_ERROR',
  REST_AUTHORIZATION_ERROR = 'REST_AUTHORIZATION_ERROR',
  REST_AUTHENTICATION_ERROR = 'REST_AUTHENTICATION_ERROR',
  GITHUB_LOGIN_ERROR = 'GITHUB_LOGIN_ERROR'
}

export type RestErrorType =
  EErrorTypes.REST_ERROR |
  EErrorTypes.REST_AUTHENTICATION_ERROR |
  EErrorTypes.REST_AUTHORIZATION_ERROR |
  EErrorTypes.REST_NOT_FOUND_ERROR;
